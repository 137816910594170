import * as Yup from 'yup';

export const schema = Yup.object().shape({
  walletAddress: Yup.string()
    .required('Wallet address is required')
    .matches(/^0x[a-fA-F0-9]{40}$/g, 'Address is not valid'),
  wusdAmount: Yup.string().required('WUSD Amount is required'),

  //
});
