'use client';

import { Card, CircularProgress } from '@mui/material';

import { isNil } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { MintCountdownTimer } from '../../../MintCountdownTimer';
import { Contracts } from '../../contracts';
import { BorderLinearProgress } from '../../../BorderLinearProgress';
import { cn } from '../../../../utils';

const START_VALUE = 5145;

export function MintProgress({
  startDate,
  endDate,
  isStartTimerGreaterThanNow,
  isEndTimerGreaterThanNow,
}) {
  const { totalSupply, isLoadingTotalSupply } = Contracts.useTotalSupply();
  const { basePrice, isLoadingBasePrice } = Contracts.useMintBasePrice();
  const [isStartTimerVisible, setIsStartTimerVisible] = useState(false);
  const [isEndTimerVisible, setIsEndTimerVisible] = useState(false);
  const [isEventFinished, setIsEventFinished] = useState(true);
  const isExclusivePrice = useMemo(() => basePrice === 700, [basePrice]);

  useEffect(() => {
    setIsStartTimerVisible(isStartTimerGreaterThanNow && !isExclusivePrice);

    setIsEndTimerVisible(isEndTimerGreaterThanNow && isExclusivePrice);

    setIsEventFinished(
      (!isStartTimerGreaterThanNow && !isEndTimerGreaterThanNow) ||
        (!isStartTimerGreaterThanNow && !isExclusivePrice)
    );
  }, [isEndTimerGreaterThanNow, isExclusivePrice, isStartTimerGreaterThanNow]);

  function progressValue() {
    const value = (totalSupply - START_VALUE) / 4;

    if (value > 100) {
      return 100;
    }
    if (value < 0) {
      return 0;
    }

    return value;
  }

  function displayValue() {
    const value = totalSupply - START_VALUE;

    if (value > 400) {
      return 400;
    }
    if (value < 0) {
      return 0;
    }
    return value;
  }

  if (isEventFinished) {
    return null;
  }

  if (isLoadingTotalSupply || !isNil(totalSupply) || isLoadingBasePrice) {
    <div className="flex items-center justify-center h-10 w-full">
      <CircularProgress size={30} />
    </div>;
  }

  return (
    <Card className="flex flex-col gap-y-4 max-w-5xl mx-auto w-full p-10">
      <h3 className="text-center md:text-lg">
        Get your GenX NFT for $700 from 6:30 to 6:45 PM UTC. Only 400
        available—15 minutes only!
      </h3>

      <div className="flex items-center justify-center">
        <MintCountdownTimer
          date={startDate}
          className={cn(
            'text-2xl md:!text-3xl self-center mt-4 hidden',
            isStartTimerVisible && 'flex'
          )}
        />
      </div>
      <div
        className={cn(
          'flex-col gap-y-2 max-w-2xl w-full mx-auto hidden',
          isEndTimerVisible && 'flex'
        )}
      >
        <div className="flex items-center justify-center">
          <MintCountdownTimer
            onComplete={() => setIsEventFinished(true)}
            date={endDate}
            className="text-2xl md:!text-3xl self-center mt-4"
          />
        </div>
        <div className="w-full flex justify-end">
          <span className="text-xs sm:text-sm">{displayValue()}/400 </span>
        </div>
        <BorderLinearProgress
          variant="determinate"
          className="h-4"
          value={progressValue()}
        />
      </div>
    </Card>
  );
}
