import { TransferWPoint } from '../../../../TransferWPoint';
import { Modal } from '../../../../ui';

export function TransferModal({ open, handleClose }) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-describedby="transfer-modal"
      title="Transfer WPoint"
    >
      <TransferWPoint />
    </Modal>
  );
}
