const SET_BALANCE_BUTTONS_STYLES = {
  minWidth: 'auto',
  padding: 1,
  ':hover': {
    bgcolor: '#000 !important',
  },
};
export const SET_BALANCE_BUTTONS = [
  { text: '25%', styles: SET_BALANCE_BUTTONS_STYLES },
  { text: '50%', styles: SET_BALANCE_BUTTONS_STYLES },
  { text: '75%', styles: SET_BALANCE_BUTTONS_STYLES },
  { text: 'MAX', styles: SET_BALANCE_BUTTONS_STYLES },
];
